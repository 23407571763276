import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { filter, into, map, path, pipe, prop, propEq, sortBy } from 'ramda';
import styled from '@emotion/styled';
import Card from '@greenberry/salal/components/Card';
import Heading from '@greenberry/salal/components/Heading';
import HeroV4 from '@greenberry/salal/components/Hero/v4';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import MainWrapper from '@greenberry/salal/components/MainWrapper';
import Section from '@greenberry/salal/components/Section';
import Text from '@greenberry/salal/components/Text';
import { debounce } from 'debounce';
import { inputStyles } from '../components/Search';
import { sectors } from '../../backend/constants';

import Layout from '../components/Layout';
import Login from '../components/Login';
import labelMap from '../constants/datoLabelMap';

import toCSV from '../utils/toCSV';
import downloadFile from '../utils/downloadFile';

const decentralIndicators = [
  'startIntroText',
  'organizationForm',
  'executiveResponsibilities',
  'organizationFormExplanation',
  'supervisoryDuties',
  'supervisoryIndependence',
  'supervisoryIndependenceExplanation',
];

const OverviewListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 1em;
`;
const OverviewListItem = styled(Card)`
  margin-bottom: 1em;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StatusIndicatorWrapper = styled.div`
  margin-bottom: ${({ expanded }) => (expanded ? '1em' : '0')};
`;

const Tools = styled.div`
  display: flex;
  width: 100%;
`;

const StatusIndicator = ({ name, value }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StatusIndicatorWrapper expanded={expanded}>
      <Heading
        component="h5"
        size="m"
        appearance="secondary"
        onClick={() => setExpanded(!expanded)}
        style={{ cursor: 'pointer' }}
      >
        {expanded ? <span>&#9660;</span> : <span>&#9654;</span>}&nbsp;
        {value === 'Onbekend' || value === '' ? (
          <Icon size="lg" type="times" color="red" />
        ) : (
          <Icon size="lg" type="check" color="lightgreen" />
        )}
        &nbsp;
        {labelMap[name]}
      </Heading>
      {expanded && (
        <Text style={{ margin: '1em' }}>
          {value === '' ? 'Geen waarde ingevuld' : value}
        </Text>
      )}
    </StatusIndicatorWrapper>
  );
};

StatusIndicator.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const PartnershipOverviewList = ({ data }) => {
  const [search, setSearch] = useState('');

  const doSearch = debounce(value => setSearch(value.toLowerCase()), 480);
  const rethinkNodes = pipe(
    path(['rethink', 'edges']),
    map(prop('node'))
  )(data);

  const transducer = map(({ internalId, ...rest }) => {
    const match = rethinkNodes.find(propEq('id', internalId));
    return {
      ...rest,
      ...match,
    };
  });

  const formatItems = pipe(
    path(['dato', 'edges']),
    map(prop('node')),
    into([], transducer),
    filter(({ name }) => name.toLowerCase().includes(search)),
    sortBy(prop('externalId'))
  );

  return (
    <>
      <Tools>
        <input
          type="search"
          onChange={e => {
            e.persist();
            doSearch(e.target.value);
          }}
          style={Object.assign({}, inputStyles, { marginRight: '1em' })}
          placeholder="Zoek op naam"
        />
        <Button
          color="primary"
          small
          styling="solid"
          type="submit"
          onClick={() => downloadFile(toCSV(data), 'export.csv', 'text/csv')}
        >
          <Icon size="lg" type="arrowCircleDown" />
          <span>CSV</span>
        </Button>
      </Tools>
      <OverviewListWrapper>
        <li>
          {map(
            ({ id, externalId, name, sector, ...rest }) => (
              <OverviewListItem key={id}>
                <Heading
                  component="h3"
                  size="xl"
                  weight="semibold"
                  appearance="secondary"
                  style={{ width: '100%' }}
                >
                  {externalId}
                  &nbsp;
                  {name}
                  <Link
                    style={{ float: 'right' }}
                    to={`/samenwerkingsverband/${sectors[sector]}/${externalId}/`}
                  >
                    <Icon size="lg" type="globeEarth" />
                  </Link>
                </Heading>
                {map(
                  key => (
                    <StatusIndicator
                      name={key}
                      value={rest[key]}
                      key={id + key + name}
                    />
                  ),
                  decentralIndicators
                )}
              </OverviewListItem>
            ),
            formatItems(data)
          )}
        </li>
      </OverviewListWrapper>
    </>
  );
};

const Stats = ({ data }) => {
  const [isLoggedIn, setLoginStatus] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoginStatus(window.localStorage.getItem('logged_in') === 'true');
    }
  }, []);

  return (
    <Layout>
      <HeroV4
        content={
          <Heading component="h1" size="xxxl" weight="semibold">
            Overzicht
          </Heading>
        }
      />
      <MainWrapper>
        <Section size="0">
          {!isLoggedIn ? (
            <Login onLogin={setLoginStatus} />
          ) : (
            <PartnershipOverviewList data={data} />
          )}
        </Section>
      </MainWrapper>
    </Layout>
  );
};

PartnershipOverviewList.propTypes = {
  data: PropTypes.shape({
    dato: PropTypes.shape({}).isRequired,
  }),
};
Stats.propTypes = PartnershipOverviewList.propTypes;

export const query = graphql`
  query allData {
    rethink: allPartnership {
      edges {
        node {
          id
          externalId
        }
      }
    }
    dato: allDatoCmsPartnership {
      edges {
        node {
          id
          internalId
          name
          sector
          slug

          startIntroText

          organizationForm
          organizationFormExplanation
          executiveResponsibilities

          supervisoryDuties
          supervisoryIndependence
          supervisoryIndependenceExplanation

          reservePositionExplanation
          studentCountExplanation
          participationRateExplanation
          supportBudgetExplanation
        }
      }
    }
  }
`;

export default Stats;
